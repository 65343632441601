import _toConsumableArray from "F:/company_project/dobay_project_new/dobay_face_h5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Vue from 'vue';
import VueRouter from 'vue-router';
import homeRouter from './modules/home';
Vue.use(VueRouter);
export var constantRoutes = _toConsumableArray(homeRouter);
export default new VueRouter({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});