import _toConsumableArray from "F:/company_project/dobay_project_new/dobay_face_h5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
var store = new Vuex.Store({
  state: {
    // [{
    //     type:'type',
    //     data:[],
    // }]
    vagetable: [],
    names: []
  },
  mutations: {
    setData: function setData(state, datas) {
      console.log("datas", datas);
      state.vagetable = datas;
    },
    setNames: function setNames(state, datas) {
      state.names = datas;
    },
    delData: function delData(state) {
      state.vagetable = [];
      state.names = [];
    },
    delName: function delName(state, name) {
      var flag = false;
      state.vagetable.forEach(function (e) {
        if (e.data.includes(name)) {
          flag = true;
          e.data.splice(e.data.findIndex(function (ee) {
            return ee == name;
          }), 1);
        }
      });

      if (!flag) {
        state.names.splice(state.names.findIndex(function (e) {
          return e == name;
        }), 1);
      }
    }
  },
  actions: {
    setData: function setData(_ref, datas) {
      var commit = _ref.commit;
      commit("setData", datas);
    },
    setNames: function setNames(_ref2, datas) {
      var commit = _ref2.commit;
      commit("setNames", datas);
    },
    delData: function delData(_ref3) {
      var commit = _ref3.commit;
      commit("delData");
    },
    delName: function delName(_ref4, name) {
      var commit = _ref4.commit;
      commit("delName", name);
    }
  },
  getters: {
    foodNames: function foodNames(state) {
      var foodNameList = [];
      state.vagetable.forEach(function (e) {
        foodNameList.push.apply(foodNameList, _toConsumableArray(e.data));
      });
      state.names.forEach(function (e) {
        if (!foodNameList.includes(e)) {
          foodNameList.push(e);
        }
      });
      return foodNameList;
    }
  }
});
export default store;