import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var homeRouter = [{
  path: "/",
  name: "Home",
  meta: {
    title: "人脸门禁录入"
  },
  component: function component() {
    return import("@/views/home");
  }
}, {
  path: "/desc",
  name: "Desc",
  meta: {
    title: "生物识别信息采集说明"
  },
  component: function component() {
    return import("@/views/desc");
  }
}, {
  path: "/result",
  name: "Result",
  meta: {
    title: "人脸信息录入"
  },
  component: function component() {
    return import("@/views/result");
  }
}, {
  path: "/app/login/tip",
  name: "AppLogin",
  meta: {
    title: "督贝督导"
  },
  component: function component() {
    return import("@/views/app-login");
  }
}, {
  path: "/app/sample/enter",
  name: "SampleEnter",
  meta: {
    title: "留样数据录入"
  },
  component: function component() {
    return import("@/views/sample-enter");
  }
}, {
  path: "/app/sample/success",
  name: "SampleSuccess",
  meta: {
    title: "留样数据录入"
  },
  component: function component() {
    return import("@/views/success");
  }
}, {
  path: "/app/sample/search",
  name: "SampleSearch",
  meta: {
    title: "留样数据录入"
  },
  component: function component() {
    return import("@/views/sample-enter/sample-search");
  }
}];
export default homeRouter;