import "F:\\company_project\\dobay_project_new\\dobay_face_h5\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "F:\\company_project\\dobay_project_new\\dobay_face_h5\\node_modules\\core-js\\modules\\es.promise.js";
import "F:\\company_project\\dobay_project_new\\dobay_face_h5\\node_modules\\core-js\\modules\\es.object.assign.js";
import "F:\\company_project\\dobay_project_new\\dobay_face_h5\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import store from "./store/index.js";
import "vant/lib/index.css";
Vue.use(Vant);
Vue.config.productionTip = false;
/* 路由发生变化修改页面title */

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  next();
});
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");